// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import 'bootstrap'
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import Swal from 'sweetalert2';
window.Swal = Swal;
import { Chart, PieController, BarController, BarElement, LinearScale, CategoryScale, DoughnutController, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import Chartkick from 'chartkick';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registrar solo lo que necesitas
Chart.register(PieController, BarController, BarElement, LinearScale, CategoryScale, DoughnutController, ArcElement, Tooltip, Legend, Title, ChartDataLabels);

Chartkick.use(Chart);

// Define una función para iniciar el temporizador
function startTimer(duration, display) {
  var timer = duration, minutes, seconds;
  var intervalId = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = minutes + ":" + seconds;

    if (timer <= 0) {
      display.classList.add('negative'); // Añade la clase para texto rojo cuando el temporizador llegue a cero
      clearInterval(intervalId); // Detiene el temporizador
    } else {
      display.classList.remove('negative'); // Asegura que la clase se elimine si el temporizador no es cero
    }

    timer--;
  }, 1000);
}

document.addEventListener("turbolinks:load", function () {
  var Minutes = 60*60*3,
  display = document.querySelector('#time');
  if(display){
    startTimer(Minutes, display);
  }

  $('[data-toggle="tooltip"]').tooltip();
});





// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
